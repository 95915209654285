import React from 'react';

export interface SwitchProps {
  conf: {
    title?:string,
    options:Array<{
      val:string,
      text:string,
      backgroundColor?:string,
      selectedClassName?:string,
    }>,
    setSelectedOption?:(a:string)=>void,
    selectedOption:string,
    className?: string,
    backgroundColor?:string,
  }
  disabled?:boolean,
}


const Switch: React.FunctionComponent<SwitchProps> = ({conf, disabled}) => {

  const {title, options, setSelectedOption, selectedOption, backgroundColor} = conf;

  const style:{backgroundColor?: string} = {};
  if (backgroundColor) {
    style.backgroundColor = backgroundColor;
  }

  const onChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) {
      return;
    }
    if (setSelectedOption) {
      if (e.target.checked) {
        setSelectedOption(options[1].val);
      } else {
        setSelectedOption(options[0].val);
      }
    }
  }
  let mainClass;
  if (disabled) {
    mainClass = 'bg-dstl-grey';
  } else {
    mainClass = 'bg-dstl-crimson';
  }
  mainClass = `block ${mainClass} w-10 h-6 rounded-full`;
  let labelClass;
  if (disabled) {
    labelClass = '';
  } else {
    labelClass = 'cursor-pointer';
  }
  labelClass = `flex items-center ${labelClass} m-0`;


  return (
    <>
      <div className = 'flex justify-center gap-2' style={style}>
        {
          title?
            <div
              style={style} 
              className='p-2 font-bold'
            >
              {title}
            </div>
            : ''
        }

        <div className="flex items-center justify-center">

          <label htmlFor="toggleSwitch" className={labelClass} title={disabled?'N/A':''}>
            <div className="mr-3 font-bold text-sm">
              {options[0].text}
            </div>
            <div className="relative">
              <input type="checkbox" id="toggleSwitch" className="sr-only"
                checked={(selectedOption === options[1].val)}
                onChange={onChange}
              />
              <div className={mainClass}/>
              <div className="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"/>
            </div>
            <div className="mx-3 font-bold text-sm">
              {options[1].text}
            </div>
          </label>

        </div>

      </div>
    </>
  )

}
export default Switch;
